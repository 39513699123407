import React from "react";
import Layout from "../components/layout";
import NewsQuery from "../components/news/NewsQuery";

const IndexPage = () => (
  <Layout>
      <div>
            <h1>ESTONIAN FIGHTING GAME LEAGUE</h1>
            <div className='body'>
              <NewsQuery />
            </div>
      </div>
  </Layout>
);

export default IndexPage;